import { FC } from 'react';

import { Typography, Box } from '@mui/material';
import { PortableText } from '@portabletext/react';

import { SanityTextBlock } from '@guider-global/shared-types';

export interface LegalNoticesProps {
  title: string | undefined;
  content: SanityTextBlock[];
}

const LegalNotices: FC<LegalNoticesProps> = ({ title, content }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexFlow: 'column nowrap',
          alignContent: 'center',
          alignItems: 'flex-start',
          justifyContent: 'center',
          my: { md: 8, xs: 6 },
          mx: { xs: 2 },
          maxWidth: theme.breakpoints.values.sm,
        })}
      >
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        <Box component={PortableText} value={content} />
      </Box>
    </Box>
  );
};

export default LegalNotices;
