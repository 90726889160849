// external
import { FunctionComponent, ReactNode } from 'react';
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface SnackbarAlertProps extends SnackbarProps {
  severity: AlertColor;
  alertContent: ReactNode;
  icon?: ReactNode;
}

export const SnackbarAlert: FunctionComponent<SnackbarAlertProps> = ({
  sx = {},
  severity,
  alertContent,
  icon,
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  ...snackbarProps
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Snackbar
        anchorOrigin={anchorOrigin}
        {...snackbarProps}
        sx={{
          ...(isMobile && { bottom: 0, left: 0, right: 0 }),
          ...sx,
        }}
      >
        <Alert
          severity={severity}
          sx={{ borderRadius: 0, ...(isMobile && { width: '100vw' }) }}
          icon={icon}
        >
          {alertContent}
        </Alert>
      </Snackbar>
    </>
  );
};
