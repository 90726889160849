import { forwardRef } from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from 'store/hooks';
import { getVideoRoomState } from 'store/slices/videoRoomSlice';

const VideoSideBar = forwardRef((props, ref) => {
  // Redux
  const videoRoomState = useAppSelector(getVideoRoomState);

  // TODO add CHAT, SESSION, GOALS
  return (
    <Box
      sx={{
        backgroundColor: 'blue',
        minWidth: { xs: '100%', md: '360px' },
        width: { xs: '100%', md: '25%' },
      }}
      ref={ref}
    >
      <h3>{videoRoomState.transitionSidebar}</h3>
      <h3>SideBar</h3>
      <h3>{videoRoomState.sidebar}</h3>
    </Box>
  );
});

export default VideoSideBar;
