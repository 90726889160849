import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Collapse,
  Snackbar,
} from '@mui/material';
import { FC } from 'react';
import VideoComposite, { VideoCompositeProps } from './VideoComposite';
import VideoNavBar, { VideoNavBarProps } from './VideoNavBar';
import VideoSideBar from './VideoSideBar';

export interface VideoRoomError {
  message: string;
  techMsg: string;
  httpCode?: string | number;
}
interface VideoRoomProps
  extends VideoNavBarProps,
    Partial<VideoCompositeProps> {
  sessionTitle: string;
  transitionSidebar?: boolean;
  loading?: boolean;
  error: VideoRoomError | undefined;
  onErrorClose: () => void;
}
const VideoRoom: FC<VideoRoomProps> = ({
  sessionTitle,
  exitSessionLabel,
  relationshipId,
  scheduleSessionLabel,
  goalsLabel,
  chatLabel,
  callAdapter,
  onFetchUserImages,
  localeCode,
  transitionSidebar,
  loading,
  error,
  onErrorClose,
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        flexFlow: 'column',
        gridTemplateColumns: '100%',
        gridTemplateRows: '60px 1fr',
        height: '100vh',
        maxHeight: { xs: 'fill-available', md: '100vh' },
      }}
    >
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '1',
        }}
      >
        <VideoNavBar
          sessionTitle={sessionTitle}
          exitSessionLabel={exitSessionLabel}
          relationshipId={relationshipId}
          scheduleSessionLabel={scheduleSessionLabel}
          goalsLabel={goalsLabel}
          chatLabel={chatLabel}
        />
      </Box>
      <Box
        sx={{
          gridColumn: '1',
          gridRow: '2',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Snackbar
          open={Boolean(error)}
          onClose={onErrorClose}
          sx={{ mt: 5 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <Alert
            onClose={onErrorClose}
            severity="error"
            sx={{ maxWidth: '100%' }}
          >
            <Accordion
              elevation={0}
              disableGutters
              sx={{
                background: 'inherit',
                backgroundColor: 'inherit',
                color: 'inherit',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="video-alert-content"
                id="video-alert-header"
                sx={{
                  color: 'inherit',
                  alignItems: 'flex-start',
                  '.MuiAccordionSummary-content': {
                    margin: 0,
                  },
                }}
              >
                <AlertTitle
                  sx={{
                    color: 'inherit',
                  }}
                >
                  {error?.message}
                </AlertTitle>
              </AccordionSummary>
              <AccordionDetails>
                {error?.httpCode}
                {error?.techMsg}
              </AccordionDetails>
            </Accordion>
          </Alert>
        </Snackbar>
        {!callAdapter || loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <VideoComposite
            callAdapter={callAdapter}
            localeCode={localeCode}
            sessionTitle={sessionTitle}
            onFetchUserImages={onFetchUserImages}
          />
        )}

        <Collapse in={transitionSidebar} orientation="horizontal">
          <VideoSideBar />
        </Collapse>
      </Box>
    </Box>
  );
};

export default VideoRoom;
