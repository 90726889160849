import { Button, ButtonProps, IconButton } from '@mui/material';
import { useMobileMediaQuery } from 'hooks';
import { FC, ReactNode } from 'react';

interface NavBarButtonProps extends ButtonProps {
  icon: ReactNode;
  label: string | undefined;
  'data-cy': string;
}

const NavBarButton: FC<NavBarButtonProps> = ({
  label,
  icon,
  sx = {},
  'data-cy': dataCy,
  ...rest
}) => {
  // Styling
  const isMobile = useMobileMediaQuery();

  if (isMobile)
    return (
      <IconButton
        data-cy={`components_VideoPage_navBar-${dataCy}`}
        sx={{
          color: 'primary.contrastText',
          backgroundColor: 'info.main',
          ':hover': {
            backgroundColor: 'info.light',
          },
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          gap: (theme) => theme.spacing(1),
          width: 'max-content',
          fontSize: '15px',
          p: 1.5,
          ...sx,
        }}
        {...rest}
      >
        {icon}
      </IconButton>
    );

  return (
    <Button
      size="small"
      disableElevation
      variant="contained"
      data-cy={`components_VideoPage_navBar-${dataCy}`}
      sx={{
        color: 'primary.contrastText',
        backgroundColor: 'info.main',
        ':hover': {
          backgroundColor: 'info.dark',
        },
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: (theme) => theme.spacing(1),
        width: 'max-content',
        ...sx,
      }}
      {...rest}
    >
      {icon}
      {label}
    </Button>
  );
};
export default NavBarButton;
