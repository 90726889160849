import { FC } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';

import { useAppSelector } from 'store/hooks';

import { getVideoRoomState } from 'store/slices/videoRoomSlice';

import InCallNavBarButtons from './InCallNavBarButtons';
import ExistSessionButtons from './ExistSessionButtons';
import NavBarButton from './NavBarButton';
import { useMobileMediaQuery } from 'hooks';

export interface VideoNavBarProps {
  relationshipId: string;
  sessionTitle: string;
  exitSessionLabel: string | undefined;
  scheduleSessionLabel: string | undefined;
  goalsLabel: string | undefined;
  chatLabel: string | undefined;
}
const VideoNavBar: FC<VideoNavBarProps> = ({
  relationshipId,
  sessionTitle,
  exitSessionLabel,
  scheduleSessionLabel,
  goalsLabel,
  chatLabel,
}) => {
  // Style
  const isMobile = useMobileMediaQuery();
  // Redux;
  const videoRoomState = useAppSelector(getVideoRoomState);
  const { callCompositePage } = videoRoomState;

  const showExitSession = callCompositePage !== ('call' || 'lobby');
  const renderButtons = showExitSession ? (
    <ExistSessionButtons
      relationshipId={relationshipId}
      exitSessionLabel={exitSessionLabel}
    />
  ) : (
    <InCallNavBarButtons
      scheduleSessionLabel={scheduleSessionLabel}
      goalsLabel={goalsLabel}
      chatLabel={chatLabel}
    />
  );

  if (!sessionTitle)
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'primary.main',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          alignContent: 'center',
          maxWidth: '100%',
          gap: { xs: 2, md: 3 },
          px: { xs: 2, md: 3 },
        }}
      >
        <Typography variant="h6" component="h1">
          <Skeleton variant="text" width="200px" sx={{ bgcolor: 'grey.900' }} />
        </Typography>

        <Skeleton
          variant={isMobile ? 'circular' : 'rectangular'}
          sx={{ bgcolor: 'grey.900' }}
        >
          <NavBarButton
            icon={<AiOutlineClose />}
            label={exitSessionLabel}
            sx={{
              backgroundColor: 'error.main',
            }}
            data-cy={'exit-session-loading-button'}
          />
        </Skeleton>
      </Box>
    );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'primary.main',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        alignContent: 'center',
        maxWidth: '100%',
        gap: { xs: 2, md: 3 },
        px: { xs: 2, md: 3 },
      }}
    >
      <Typography
        variant="h6"
        component="h1"
        sx={{
          color: 'primary.contrastText',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {sessionTitle}
      </Typography>
      {renderButtons}
    </Box>
  );
};

export default VideoNavBar;
