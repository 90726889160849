import {
  CallCompositeStrings,
  CallWithChatCompositeStrings,
  ChatCompositeStrings,
  ComponentLocale,
  CompositeLocale,
  COMPOSITE_LOCALE_EN_GB,
  COMPOSITE_LOCALE_EN_US,
  COMPOSITE_LOCALE_FR_FR,
} from '@azure/communication-react';
import { SanityLocaleText } from '@guider-global/shared-types';

export type LocaleCode = keyof SanityLocaleText;

interface Options {
  strings?: {
    chat?: Partial<ChatCompositeStrings>;
    call?: Partial<CallCompositeStrings>;
    callWithChat?: Partial<CallWithChatCompositeStrings>;
  };
  component?: Partial<ComponentLocale>;
}
export interface VideoLocale {
  localeCode: LocaleCode;
  options?: Options;
}

const mergeComposite = (
  compositeLocale: CompositeLocale,
  options?: Options,
): CompositeLocale => {
  return {
    strings: {
      chat: {
        ...compositeLocale.strings.chat,
        ...options?.strings?.chat,
      },
      call: {
        ...compositeLocale.strings.call,
        ...options?.strings?.call,
      },
      callWithChat: {
        ...compositeLocale.strings.callWithChat,
        ...options?.strings?.callWithChat,
      },
    },
    component: { ...compositeLocale.component, ...options?.component },
  };
};

export const videoLocale = ({
  localeCode,
  options,
}: VideoLocale): CompositeLocale => {
  switch (localeCode) {
    case 'en_GB':
      return mergeComposite(COMPOSITE_LOCALE_EN_GB, options);
    case 'en_US':
      return mergeComposite(COMPOSITE_LOCALE_EN_US, options);
    case 'fr_CA':
      return mergeComposite(COMPOSITE_LOCALE_FR_FR, options);
    case 'fr_FR':
      return mergeComposite(COMPOSITE_LOCALE_FR_FR, options);

    default:
      return mergeComposite(COMPOSITE_LOCALE_EN_GB, options);
  }
};
