import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';
import { Avatar } from '@mui/material';

import { useEffect, useState } from 'react';
import { IRecipients, getRecipients } from 'utils/getRelationshipRecipients';

export interface AvatarProps {
  relationshipId: string | undefined;
  isGuide: boolean;
}

export function SurveyAvatar({
  relationshipId,
  isGuide,
}: Readonly<AvatarProps>) {
  // Local state
  const [otherPartyProfile, setOtherPartyProfile] = useState<IRecipients>();

  // Profiles
  const { profile, isLoadingProfiles } = useProfiles({});
  const userId = profile?.userId;

  // Relationship profiles
  const { relationships, isLoadingRelationships } = useRelationships({});

  useEffect(() => {
    if (isLoadingProfiles || isLoadingRelationships || !relationshipId) {
      return;
    }

    const relationship = relationships?.find(
      (relationship) => relationship.id === relationshipId,
    );

    if (!relationship) {
      return;
    }

    const recipients = getRecipients({
      role: isGuide ? 'guide' : 'trainee',
      relationship,
      userId,
    });

    const otherPartyProfile = recipients.at(0);
    setOtherPartyProfile(otherPartyProfile);
  }, [
    isLoadingProfiles,
    isLoadingRelationships,
    relationships,
    userId,
    relationshipId,
    isGuide,
  ]);

  const { getProfileImage } = useProfileImage();
  const image = getProfileImage({
    profilePicture: otherPartyProfile?.picture,
    userPicture: otherPartyProfile?.userPicture,
  });

  return <Avatar src={image} sx={{ width: '64px', height: '64px' }} />;
}
